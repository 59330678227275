import React from "react";
import {
  UilFileDownload,
  UilHouseUser,
  UilAward,
  UilFolderLock,
  UilAt,
} from "@iconscout/react-unicons";

// Importing the costume components
import Slider from "./Slider/Slider";

// Importing the style file
import "./Navbar.css";

// Creating the Navbar component
const Navbar = React.forwardRef(({ observers }, ref) => {
  // Destructuring the refs
  const { aboutRef, skillRef, projectsRef, contactRef } = ref;

  // Function that will scroll to the selected section
  const scrollToSection = (reference) => {
    reference.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="navbar-container">
      <div className="nav-limiter">
        <div className="navbar-buttons">
          <div className="navbar-links">
            <button
              className={observers.aboutInView ? "visible" : ""}
              onClick={() => scrollToSection(aboutRef)}
            >
              <p>About</p>
              <span>
                <UilHouseUser />
                <small>About</small>
              </span>
            </button>
            <button
              className={observers.skillsInView ? "visible" : ""}
              onClick={() => scrollToSection(skillRef)}
            >
              <p>Skills</p>
              <span>
                <UilAward />
                <small>Skills</small>
              </span>
            </button>
            <button
              className={observers.projectsInView ? "visible" : ""}
              onClick={() => scrollToSection(projectsRef)}
            >
              <p>Projects</p>
              <span>
                <UilFolderLock />
                <small>Projects</small>
              </span>
            </button>
            <button
              className={observers.contactInView ? "visible" : ""}
              onClick={() => scrollToSection(contactRef)}
            >
              <p>Contact</p>
              <span>
                <UilAt />
                <small>Contact</small>
              </span>
            </button>
          </div>
          <a href="Jean-Pierre_Novak.pdf" download="Jean-Pierre_Novak_CV.pdf">
            Download CV <UilFileDownload />
          </a>
        </div>
        <Slider />
      </div>
    </div>
  );
});

// Exporting the Navbar component
export default Navbar;
