import React from "react";

// Importing the style file
import "./Skills.css";
import SolarSystem from "./SolarSystem/SolarSystem";

// Creating the Skills component
const Skills = React.forwardRef((props, ref) => {
  return (
    <div className="skills-container" ref={ref.skillRef}>
      <div className="skills-limiter" ref={ref.skillVisible}>
        <h2>Skills</h2>
        <p>
          I am an enthusiastic learner who is ready to learn new things all the
          time. Here are the skills I am most comfortable with.
        </p>
        <SolarSystem />
      </div>
    </div>
  );
});

// Exporting the Skills component
export default Skills;
