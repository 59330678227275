import React from "react";
import {
  UilCopyright,
  UilGithub,
  UilLinkedin,
  UilEnvelopeOpen,
  UilPhone,
} from "@iconscout/react-unicons";

// Importing the style file
import "./Footer.css";

// Creating the Footer component
const Footer = React.forwardRef((props, ref) => {
  return (
    <div className="footer-container">
      <div className="footer-limiter">
        <div className="footer-info">
          <div className="footer-page-links">
            <button
              onClick={() =>
                ref.aboutRef.current?.scrollIntoView({ behavior: "smooth" })
              }
            >
              About
            </button>
            <button
              onClick={() =>
                ref.skillsRef.current?.scrollIntoView({ bahavior: "smooth" })
              }
            >
              Skills
            </button>
            <button
              onClick={() =>
                ref.projectsRef.current?.scrollIntoView({ behavior: "smooth" })
              }
            >
              Projects
            </button>
            <button
              onClick={() =>
                ref.contactRef.current?.scrollIntoView({ behavior: "smooth" })
              }
            >
              Contact
            </button>
          </div>
          <div className="footer-links">
            <a
              href="https://github.com/Zack1808"
              target="_blank"
              rel="noreferrer"
            >
              <UilGithub size={45} />
              <small>Github</small>
            </a>
            <a
              href="https://www.linkedin.com/in/jean-pierre-novak-2425a7218/"
              target="_blank"
              rel="noreferrer"
            >
              <UilLinkedin size={45} />
              <small>LinkedIn</small>
            </a>
          </div>
          <div className="footer-contact-information">
            <div className="footer-spans">
              <span>
                <a
                  href="mailto: jeanpierrenovak23@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <UilEnvelopeOpen />
                  jeanpierrenovak23@gmail.com
                </a>
              </span>
              <span>
                <UilPhone />
                +385 91 975 4934
              </span>
            </div>
          </div>
        </div>
        <p>
          <UilCopyright /> JPN {new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
});

// Exporting the Footer component
export default Footer;
