import React from "react";

// Importing the style file
import "./Input.css";

// Creating the Input component
const Input = ({
  placeholder = "placeholder",
  type = "text",
  name = "name",
}) => {
  return (
    <div className="input-container">
      <input type={type} placeholder={placeholder} name={name} required />
    </div>
  );
};

// Exporting the Input component
export default Input;
