import React, { useState, useContext, useEffect } from "react";

// Creating the context
const ThemeContext = React.createContext();
const ThemeUpdateContext = React.createContext();

// Creating the hooks for using the context
export const useTheme = () => {
  return useContext(ThemeContext);
};

export const useUpdateTheme = () => {
  return useContext(ThemeUpdateContext);
};

// Creating and exporting the Provider
export const ThemeProvider = ({ children }) => {
  // Setting up the state
  const [dark, setDark] = useState(false);

  // Function that will toggle the state
  const toggle = () => {
    setDark((prevState) => !prevState);
  };

  // Fetching the dark mode state from the localStorage
  useEffect(() => {
    setDark(JSON.parse(localStorage.getItem("jpn-portfolio-dark-mode")));

    // eslint-disable-next-line
  }, []);

  // Setting the current dark mode value to the localStorage
  useEffect(() => {
    localStorage.setItem("jpn-portfolio-dark-mode", JSON.stringify(dark));

    // eslint-disable-next-line
  }, [dark]);

  return (
    <ThemeContext.Provider value={dark}>
      <ThemeUpdateContext.Provider value={toggle}>
        {children}
      </ThemeUpdateContext.Provider>
    </ThemeContext.Provider>
  );
};
