import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

// Importing costume components
import Input from "./Input/Input";
import Button from "../Button/Button";

// Importing the style file
import "./Contact.css";

// Creating the Contact component
const Contact = React.forwardRef((props, ref) => {
  // Defining the refs
  const formRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_y1p4f37",
        "template_hxpmik6",
        formRef.current,
        "IRe9-WimUiiiHdVpY"
      )
      .then(
        (result) => {
          toast.success("Message successfuly sent", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        },
        (error) => {
          toast.error(`Could not send the message. Error: ${error}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      );
    e.target.reset();
  };

  return (
    <div className="contact-container" ref={ref.contactRef}>
      <div className="contact-limiter" ref={ref.contactVisible}>
        <h2>Contact</h2>
        <p>Got questions? Ask away. I'll respond within 24h.</p>
        <form ref={formRef} onSubmit={handleSubmit}>
          <div className="inputs">
            <Input placeholder="Name" type="text" name="user_name" />
            <Input placeholder="Email" type="email" name="user_email" />
            <Input placeholder="Subject" type="text" name="subject" />
          </div>
          <textarea placeholder="Message" name="message" required></textarea>
          <div className="button">
            <Button hover="left" text="Send message" type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
});

// Exporting the Contact component
export default Contact;
