import React from "react";

// Importing the costume components
import ProjectItem from "./ProjectItem/ProjectItem";

// Importing the images
import webshopImage from "../../images/mateo-nCU4yq5xDEQ-unsplash.png";
import enigmaImage from "../../images/markus-spiske-iar-afb0qqw-unsplash.png";
import weatherImage from "../../images/brian-mcgowan-kKyxIwvljBg-unsplash.png";
import quizImage from "../../images/laurin-steffens-IVGZ6NsmyBI-unsplash.png";
import recipeImage from "../../images/s-o-c-i-a-l-c-u-t-hwy3W3qFjgM-unsplash.png";

// Importing the style file
import "./Projects.css";

// Creating the Projects component
const Projects = React.forwardRef((props, ref) => {
  // Creating the variables
  const projects = [
    {
      id: 1,
      title: "Webshop",
      description:
        "A React app that uses Commerce.js as a backend platform and Stripe.js as the payment platform.",
      gitlink: "https://github.com/Zack1808/webshop",
      pagelink: "https://gamertech-project.netlify.app",
      image: webshopImage,
      double: true,
      full: false,
    },
    {
      id: 2,
      title: "Enigma machine",
      description:
        "A React app that eincrypts and decrypts messages using the rotor system of the enigma machine",
      gitlink: "https://github.com/Zack1808/enigma-machine",
      pagelink: "https://zack1808.github.io/enigma-machine/",
      image: enigmaImage,
      double: false,
      full: false,
    },
    {
      id: 3,
      title: "Quiz Game",
      description:
        "A React app that uses the Open Trivia API to fetch questions and answers. The app keeps a track of the users score and displays how many answers were correct.",
      gitlink: "https://github.com/Zack1808/quiz-game",
      pagelink: "https://zack1808.github.io/quiz-game/",
      image: quizImage,
      double: false,
      full: false,
    },
    {
      id: 4,
      title: "Weather App",
      description:
        "A React app that uses the Openweather API to fetch the information about the current weather. It also allows the user to switch the metric units and to check the weather stauts of any city the user enters in the search field",
      gitlink: "https://github.com/Zack1808/weather-app",
      pagelink: "https://weather-app-jpn.netlify.app",
      image: weatherImage,
      double: true,
      full: false,
    },
    {
      id: 5,
      title: "Recetopia",
      description:
        "A React app using Redux for state management. It allows the user to create an acconut, login and manage recipes. The user is able to search for recipes using their title and using the recipe tags.",
      gitlink: "https://github.com/Zack1808/recetopia",
      pagelink: "https://recetopia.netlify.app",
      image: recipeImage,
      double: false,
      full: true,
    },
  ];

  return (
    <div className="projects-container" ref={ref.projectsRef}>
      <div className="projects-limiter" ref={ref.projectsVisible}>
        <h2>Projects</h2>
        <p>Here is a list of some of the projects I have created.</p>
        <div className="projects-list">
          {projects.map((project) => (
            <ProjectItem
              title={project.title}
              double={project.double}
              image={project.image}
              gitlink={project.gitlink}
              desc={project.description}
              link={project.pagelink}
              key={project.id}
              full={project.full}
            />
          ))}
        </div>
      </div>
    </div>
  );
});

// Exporting the Projects component
export default Projects;
