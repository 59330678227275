import React from "react";

// Importing costume components
import Button from "../Button/Button";

// Importing the style file
import "./About.css";

// Creating the About component
const About = React.forwardRef(({ sectionType = "" }, ref) => {
  return (
    <div className={`about-container ${sectionType}`} ref={ref.aboutRef}>
      <div className="about-limiter" ref={ref.aboutVisible}>
        <h2>About</h2>
        <p>
          I am an aspiring <strong>selftaught</strong> Front-End developer from
          Velika Gorica, Croatia. My journey as an developer started in 2018
          whilst being a student at the Tehnical school Ruđer Bošković, Zagreb.
        </p>
        <p>
          Currently studying <strong>Computer Design</strong> at the Tehnical
          University in Zagreb.
        </p>
        <p>
          Besides creating functional web pages, I love to solve
          <strong> puzzles, playing the guitar</strong> and
          <strong> cooking</strong>.
        </p>
        <Button
          text="Download CV"
          hover="left"
          type="link"
          link="Jean-Pierre_Novak.pdf"
          download="Jean-Pierre_Novak_CV.pdf"
        />
      </div>
    </div>
  );
});

// Exporting the component
export default About;
