import React from "react";

// Importing the costume components
import Button from "../../Button/Button";

// Importing the style file
import "./ProjectItem.css";

// Creating the ProjectItem component
const ProjectItem = ({ title, link, desc, image, double, full, gitlink }) => {
  return (
    <div
      className={`item-container ${double ? "double" : ""} ${
        full ? "full" : ""
      }`}
    >
      <div
        className="item-background"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="item-information">
          <h3>{title}</h3>
          <p>{desc}</p>
          <div className="buttons">
            <Button
              type="link"
              link={gitlink}
              text="Check the repo"
              hover="right"
              container
            />
            <Button
              type="link"
              link={link}
              text="Check live page"
              hover="right"
              container
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// Exportign the ProjectItem component
export default ProjectItem;
