import React from "react";

// Importing the style file
import "./Button.css";

// Creating the Button component
const Button = ({
  type = "button",
  link = "#",
  text = "text",
  onClick,
  hover,
  container,
  download = "",
}) => {
  if (type === "link")
    return (
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className={`btn ${hover} ${container ? "container" : ""}`}
        download={download}
      >
        {text}
      </a>
    );

  return (
    <button
      className={`btn ${hover} ${container ? "container" : ""}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

// Exporting the Button component
export default Button;
