import React, { useRef } from "react";
import { useInView } from "react-intersection-observer";
import { ToastContainer } from "react-toastify";

// Importing the context
import { useTheme } from "../context/ThemeContext";

// Importing the costume components
import Navbar from "./Navbar/Navbar";
import Hero from "./Hero/Hero";
import About from "./About/About";
import Skills from "./Skills/Skills";
import Projects from "./Projects/Projects";
import Contact from "./Contact/Contact";
import Footer from "./Footer/Footer";

// Importing the style file
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

// Creating the App component
const App = () => {
  // Setting up the state

  // Setting up the refs
  const aboutRef = useRef();
  const skillRef = useRef();
  const projectsRef = useRef();
  const contactRef = useRef();

  // Setting up the observers
  const [aboutVisible, aboutInView] = useInView({ threshold: 0.2 });
  const [skillVisible, skillsInView] = useInView({ threshold: 0.2 });
  const [projectsVisible, projectsInView] = useInView({ threshold: 0.2 });
  const [contactVisible, contactInView] = useInView({ threshold: 0.2 });

  // Setting up the context
  const dark = useTheme();

  return (
    <div className={`app-container ${dark ? "dark" : ""}`}>
      <Navbar
        ref={{ aboutRef, skillRef, projectsRef, contactRef }}
        observers={{ aboutInView, skillsInView, projectsInView, contactInView }}
      />
      <ToastContainer style={{ marginTop: "4em" }} />
      <Hero reference={aboutRef} />
      <About ref={{ aboutRef, aboutVisible }} sectionType="cross" />
      <Skills ref={{ skillRef, skillVisible }} />
      <Projects ref={{ projectsRef, projectsVisible }} />
      <Contact ref={{ contactRef, contactVisible }} />
      <Footer ref={{ aboutRef, skillRef, projectsRef, contactRef }} />
    </div>
  );
};

// Exporting the App component
export default App;
