import React from "react";
import ReactDOM from "react-dom/client";

// Importing the App copmonent
import App from "./components/App";
import { ThemeProvider } from "./context/ThemeContext";

// Getting the root div
const root = ReactDOM.createRoot(document.getElementById("root"));

// Rendering the App component into the root div
root.render(
  <ThemeProvider>
    <App />
  </ThemeProvider>
);
