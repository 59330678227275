import React from "react";
import { useInView } from "react-intersection-observer";

// Importing the style file
import "./SolarSystem.css";

// Creating the SolarSystem component
const SolarSystem = () => {
  // Setting up the intersection observer
  const [ref, inView] = useInView({ threshold: 0.2 });

  return (
    <div ref={ref} className={`solar-container ${inView ? "visible" : ""}`}>
      <div className="planet sun" />
      <div className="planet mercury" />
      <div className="planet venus" />
      <div className="planet earth" />
      <div className="planet mars" />
      <div className="planet jupiter" />
      <div className="saturn-container">
        <div className="planet saturn" />
        <div className="ring" />
        <div className="planet up" />
      </div>
    </div>
  );
};

// Exporting the SolarSystem component
export default SolarSystem;
