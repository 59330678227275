import React from "react";

// Importing the context
import { useTheme, useUpdateTheme } from "../../../context/ThemeContext";

// Importing the style file
import "./Slider.css";

// Creating the ToggleButton component
const Slider = () => {
  // Creating the context variables
  const dark = useTheme();
  const setDark = useUpdateTheme();

  return (
    <div
      className="slider-button-container"
      data-toggle={dark}
      onClick={setDark}
    >
      <div className="handle"></div>
    </div>
  );
};

// Exporting the Slider component
export default Slider;
