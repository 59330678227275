import React from "react";
import Typewriter from "typewriter-effect";

// Importing costume components
import Button from "../Button/Button";

// Importing the style file
import "./Hero.css";

// Creating the Hero component
const Hero = ({ reference }) => {
  // Setting up the typewriter option
  const typeOptions = {
    strings: ["A selftaught Front-End developer."],
    autoStart: true,
    loop: true,
  };

  return (
    <div className="hero-container">
      <div className="hero-limiter">
        <div className="hero-text">
          <h1>
            Hi, I'm <br />
            Jean-Pierre Novak
          </h1>
          <small>
            <Typewriter options={typeOptions} />
          </small>
        </div>
        <Button
          text="Read more"
          hover="top"
          onClick={() =>
            reference.current?.scrollIntoView({ behavior: "smooth" })
          }
        />
      </div>
    </div>
  );
};

// Exporting the Hero component
export default Hero;
